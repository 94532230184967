import { Provider, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ErrorFallback from "components/Error";
import { ErrorBoundary } from "react-error-boundary";
import ForgotPass from "screens/ForgotPass";
import OtpVerify from "screens/OtpVerify";
import Registration from "screens/Registration";
import ResetPassword from "screens/ResetPassword";
import Signin from "screens/Signin";
import MaintenancePage from "screens/MaintenancePage";
import RegSucessful from "screens/PasswordUpdateSuccess";
// import RegSucessful from "screens/RegSucessful";

let unAuthRoutes = [
  { component: Registration, path: "Registration/:string" },
  { component: ForgotPass, path: "ForgotPass" },
  { component: OtpVerify, path: "OtpVerify" },
  { component: ResetPassword, path: "ResetPassword" },
  { component: Signin, path: "Signin" },
  { component: RegSucessful, path:"PasswordUpdateSuccess"},
  //   { component: Signin, path: "Signin/:string" },
  // { component: MaintenancePage, path: "Signin" },
  // { component: MaintenancePage, path: "Signin/:string" },
];

const Unauthorized = ({ children }) => {
  const user = useSelector((state) => state.user);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
      {/* {user.isAuthorized ? <Navigate to="/" /> : children} */}
    </ErrorBoundary>
  );
};

export default function UnAuthRoutes() {
  return unAuthRoutes.map((r, i) => {
    let UnAuthComponent = r.component;
    return (
      <Route
        path={`/${r.path}`}
        element={
          <Unauthorized>
            <UnAuthComponent page={r.props?.page} />
          </Unauthorized>
        }
        key={i}
      />
    );
  });
}
